import React from "react";
import { Link } from "gatsby";

import { Typography } from "@material-ui/core";

import Layout from "../../components/Layout";
import Footer from "../../components/Footer";

const PrivacyPolicyPage = () => {
  return (
    <Layout title="Reconcile" noBottomNav maxWidth="md">
      <div style={{ padding: "50px 15px 0" }}>
        <Typography variant="h5" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last updated: 05/12/2020
        </Typography>
        <Typography variant="body1" gutterBottom>
          Thank you for choosing to use <Link to="/">Reconcile</Link>. We are
          committed to protecting your personal information and your right to
          privacy. If you have any questions or concerns about our policy, or
          our practices with regards to your personal information, please
          contact me at{" "}
          <a href="mailto:oleary.gabe@gmail.com">oleary.gabe@gmail.com</a>.
        </Typography>
        <Typography variant="h5" gutterBottom>
          What is covered
        </Typography>
        <Typography variant="body1" gutterBottom>
          This policy covers the data Reconcile ("we") collect about you
          ("user"), and how we use it.
        </Typography>
        <Typography variant="h5" gutterBottom>
          What is not covered
        </Typography>
        <Typography variant="body1" gutterBottom>
          This policy does not cover what third party services we use do with
          your data. In the interest of transparency we are listing all 3rd
          party services and linking to their respective privacy policies.
          Please click through and read them if you are concerned about their
          practices.
        </Typography>
        <ul>
          <li>
            <a href="https://stripe.com/privacy" target="_new">
              Stripe
            </a>{" "}
            - collect billing information & process subscription payments.
          </li>

          <li>
            <a
              href="https://plaid.com/legal/#end-user-privacy-policy"
              target="_new"
            >
              Plaid
            </a>{" "}
            - connect to your bank/credit card issuer and obtain transaction
            information.
          </li>
          <li>
            <a href="https://sentry.io/privacy/" target="_new">
              Sentry
            </a>{" "}
            - error tracking to help us build a better project.
          </li>
          <li>
            <a
              href="https://firebase.google.com/support/privacy/"
              target="_new"
            >
              Firebase
            </a>{" "}
            - Cloud services provider to store encrypted data in a way that
            makes it accessible using your account from any device.
          </li>
          <li>
            <a href="https://policies.google.com/privacy" target="_new">
              Google Analytics
            </a>{" "}
            - user analytics to help improve the product.
          </li>
        </ul>
        <Typography variant="h5" gutterBottom>
          Information we store about you
        </Typography>
        <Typography variant="h6" gutterBottom>
          Provided by you
        </Typography>
        <ul>
          <li>
            <strong>Email address</strong> - Provided at signup either directly
            or through usage of a third party account (Google, Facebook etc.)
          </li>
          <li>
            <strong>Billing information</strong> - If you subscribe to our
            service you may provide billing details. These are never directly
            accessed by us, they are used securely by Stripe as listed above.
          </li>
        </ul>
        <Typography variant="h6" gutterBottom>
          Collected using 3rd party services
        </Typography>
        <ul>
          <li>
            <strong>Transactions</strong> - these are obtained by using Plaid as
            listed above. We do not at any point have access to your personal
            credentials. We have read only access to your transactions and you
            may terminate this access at any time and delete all data.
          </li>
          <li>
            <strong>Billing information</strong> - if you subscribe to our
            service you may provide billing details. These are never directly
            accessed by us, they are use securely by Stripe as listed above.
          </li>
          <li>
            <strong>Usage information</strong> - in order to make decisions
            about the product we collect information about your usage such as
            page views, & actions. There is no personally identifiable
            information attached.
          </li>
        </ul>
        <Typography variant="h5" gutterBottom>
          How do we use your data?
        </Typography>
        <Typography variant="body1" gutterBottom>
          We collect the data listed above in order to operate our product and
          provide the functionality listed on our{" "}
          <a href="https://reconcile.app/" target="_new">
            landing page
          </a>
          .
        </Typography>
        <ul>
          <li>Enable you to create expenses on Splitwise and/or Venmo.</li>
          <li>
            Watch for finalized transactions after you have created an expense
            from a pending transaction.
          </li>
          <li>
            Watch for recurring transactions after you have indicate you would
            like an action performed on this type of transaction.
          </li>
          <li>Notify you of new transactions.</li>
        </ul>
        <Typography variant="body1" gutterBottom>
          We will never share your personal infomation with anyone outside of
          the relationships listed above which are necessary to provide the
          functionality of our product. We will not serve ads on our product.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Data retention
        </Typography>
        <Typography variant="body1" gutterBottom>
          We will retain your data as long as you have an account with us or
          until you indicate you'd like your data deleted. You may do this
          through that app or by{" "}
          <a href="mailto:oleary.gabe@gmail.com">contacting me</a>. At that time
          we will delete all of your personal data on our platform and terminate
          access to your financial accounts.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Updates to this policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          For any major changes to this policy we will make sure to reach out to
          any existing users by email to ensure they understand the changes.
        </Typography>
      </div>
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicyPage;
